.installAppModal__body {
  text-align: center;
}

.installAppModal__icon {
  margin: 0 auto;
  width: 125px;
  height: 125px;
  background-image: url('~common/icons/provider-blocker.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.installAppModal__title {
  margin: 2rem auto;
}