@import "src/_mixin.scss";

.benefits {
  @include container;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include min_media('medium') {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &__wrap {
    margin-top: 1.5rem;
    padding: 1.5rem 0;

    @include min_media('medium') {
      margin-top: 3rem;
    }
  }
}
