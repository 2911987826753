.appButtons {
  display: flex;
  justify-content: space-between;
  min-width: 460px;

  .appButtons__button {
    flex: 1;
    margin: 0 5px;
  }
}
