@import "src/_mixin.scss";

.providerBlog {
  padding: 2rem 0 3rem;

  @include min_media('medium') {
    padding: 3rem 0 4rem;
  }
}

.providerBlog__container {
  @include container;
}

.providerBlog__cards {
  margin-top: 2.5rem;
  align-items: flex-start;

  @include min_media('medium') {
    margin-top: 3rem;
  }
}
