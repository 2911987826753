@import "src/_mixin.scss";

.locationSelect {
  $trans-dur: 0.3s;
  $inputHeight: 55px;
  $inputHeight_compact: 45px;
  $inputHeightMobile: 45px;

  width: 100%;

  &__wrapper {
    position: relative;
    display: flex;

    @include media('mobile') {
      flex-direction: column;
    }
  }

  &__inputWrapper {
    flex: 1;
    position: relative;

    &_error {
      margin-bottom: 7px;
    }

    @include media('mobile') {
      margin-bottom: 10px;

      &_compact {
        margin-bottom: 0;
      }
    }
  }

  &__input {
    @include border($border-light, 2px);
    @include focus_state;
    @include lato_font(17px, 21px, 400, transparent);
    width: 100%;
    height: $inputHeight;
    border-right-width: 0;
    border-top-left-radius: $global-border-radius;
    border-bottom-left-radius: $global-border-radius;
    padding: 5px 15px;
    position: relative;
    z-index: 3;
    background: transparent url('~common/icons/general/icon_location.svg') 8px center no-repeat;
    background-size: 20px 20px;
    color: transparent;
    transition: border-color 0.2s;

    &:focus {
      background-color: $white;
      color: $text-dark-grey;
      border-color: $cta;
    }

    &:placeholder-shown {
      background-color: $white;
    }

    &::placeholder {
      color: $text-light-grey;
    }

    @include media('mobile') {
      height: $inputHeightMobile;
      @include font_params(14px, 16px);
      @include border($cta, 2px);
      border-radius: 4px;
    }

    &_open {
      border-bottom-left-radius: 0;
    }

    &_compact {
      @include border;
      border-radius: $global-border-radius;
      height: $inputHeight_compact;
      background-position-y: center;
      font-size: 15px !important;

      &:focus {
        border-color: $primary;
      }
    }

    &_error {
      border-color: $error;
    }
  }

  &__formatedOutput {
    @include lato_font(17px, $inputHeight, 700, $text-dark-grey);
    height: $inputHeight;
    margin-bottom: -$inputHeight;
    padding: 0 17px;
    text-align: left;
    border-left: 2px solid transparent; // To align with input underneath
    background-color: #fff;
    background-position: 15px center;

    &_compact {
      @include lato_font(15px, $inputHeight_compact, 700, $text-dark-grey);
      height: $inputHeight_compact;
      margin-bottom: -$inputHeight_compact;
      border-left-width: 1px;
    }

    @include media('mobile') {
      @include font_params(14px, $inputHeightMobile);
      height: $inputHeightMobile;
      margin-bottom: -$inputHeightMobile;
      background-position: 12px 15px;
    }
  }

  &__input,
  &__formatedOutput {
    padding-left: 38px;

    @include media('mobile') {
      padding-left: 30px;
      padding-right: 15px;
      background-position: 8px 11px;
    }
  }

  &__formatedOutputWrap {
    position: relative;
    padding-right: 20px;
    overflow: hidden;

    &::after {
      @include border($silver-chalice, 2px);
      display: block;
      content: '';
      position: absolute;
      top: 6px;
      right: 0;
      width: 9px;
      height: 9px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      z-index: 4;
      box-sizing: border-box;
      pointer-events: none;

      @include media('mobile') {
        top: 15px;
        right: 2px;
      }
    }

    @include media('mobile') {
      display: block;
    }
  }

  &__formatedOutputCity {
    white-space: nowrap;
  }

  &__formatedOutputRegion {
    font-weight: 400;
    color: $silver-chalice;
  }

  &__formatedOutputPlaceholder {
    font-weight: 400;
  }

  &__formatedOutput_hidden &__formatedOutputWrap {
    display: none;
  }

  &__formatedOutput_hidden + &__input {
    background-color: $white;
    color: $text-dark-grey;
  }

  &__formatedOutput_compact &__formatedOutputWrap::after {
    display: none;
  }

  &__listWrapper {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    transform: rotateX(90deg) scale(0);
    transition-delay: #{$trans-dur + 0.06s};
    z-index: 2; // Bigger than .activityFeedContainer__controlButtonContainer_withGreyBackground

    @include media('mobile') {
      top: 45px;
    }

    &_open {
      transform: none;
      transition-delay: 0s !important;
    }

    &_compact {
      top: 45px;
    }
  }

  &__suggestionsWrapper {
    @include border($border-light, 2px);
    position: absolute;
    width: 100%;
    max-height: 450px;
    margin: 0;
    padding: 15px 0;
    overflow-y: auto;
    overflow-x: hidden;
    background: $white;
    border-top: none;
    border-bottom-left-radius: $global-border-radius;
    border-bottom-right-radius: $global-border-radius;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    opacity: 0;
    transform: translateY(-30px);
    transition-delay: 0.06s;
    transition: transform $trans-dur, opacity $trans-dur;

    @include media('mobile') {
      max-height: 305px;
    }

    &_open {
      opacity: 1;
      transform: none;
    }

    &::before {
      content: '';
      opacity: 0;
    }

    &_loading::before {
      @include spinner('~common/icons/spinner.svg', 15px);
      left: 6px;
      top: 8px;
      opacity: 0.3;
      transition: opacity 0.3s;
      transition-delay: 0.7s;
      z-index: 1;
    }
  }

  &__submitBtn {
    @include button_cta(16px);
    height: $inputHeight;
    padding-left: 25px !important;
    padding-right: 25px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    @include media('mobile') {
      height: $inputHeightMobile;
      overflow: hidden;
      width: 100%;
      position: relative;
      padding-right: 0;
      padding-left: 0;
      font-size: 14px;
    }
  }

  &__suggestionLabel {
    @include lato_font(15px, 16px, 400, $text-dark-grey);
    display: block;
    margin: 0;
    padding: 10px 25px;
    width: 100%;
    cursor: pointer;
    text-align: left;
    background: $white !important;

    @include media('mobile') {
      padding: 10px 20px;
    }

    &_predefined {
      font-weight: 700 !important;
    }

    &_lastDesktop {
      margin-bottom: 30px !important;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -16px;
        width: calc(100% - 50px);
        height: 1px;
        background: $border-light;
      }
    }

    &_lastMobile {
      @include media('mobile') {
        margin-bottom: 30px !important;
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -16px;
          width: calc(100% - 50px);
          height: 1px;
          background: $border-light;
        }
      }
    }

    &_hiddenMobile {
      @include media('mobile') {
        display: none;
      }
    }
  }

  // To override old Vue based location select
  // TODO: cleanup after old selector is removed
  &__suggestion {
    padding: 0 !important;

    &:hover {
      background: transparent !important;
    }
  }

  &__suggestionRegion {
    color: $silver-chalice;
  }

  &__suggestionInput {
    position: absolute !important;
  }

  &__suggestionLabel:hover,
  &__suggestionInput:checked + &__suggestionLabel {
    background: #f5f5f5 !important;
  }

  &__errorMessage {
    @include lato_font(13px, 16px, 400, $error);
    margin-top: 5px;

    @include media('mobile') {
      top: calc(100% + 2px);
    }
  }
}
