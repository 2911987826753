@import "src/_mixin.scss";

.providerRegister__ctaSection {
  margin: 2rem 0;

  @include min_media('medium') {
    margin: 3rem 0;
  }
}

.providerRegister__icon {
  vertical-align: middle;
  margin-right: 1ch;
}
