@import "src/_mixin.scss";

.brandStats {
  @include container;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include min_media('medium') {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &__wrap {
    display: flex;
    row-gap: 40px;
    margin-top: 50px;
    justify-content: space-between;
    @include media('mobile') {
      margin-top: 30px;
      flex-direction: column;
    }
  }
  &__item {
    text-align: center;
    width: 300px;
    height: 205px;
    background-color: $dark-grey;
    border-radius: 10px;
    @include media('mobile') {
      width: auto;
    }
    &Title {
      color: $white;
      margin: 40px 0 20px 0;
    }
    &Text {
      color: $white;
    }
  }
}
