@import "src/_mixin.scss";

.mediaAboutUs {
  @include container;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include min_media('medium') {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &__wrap {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    @include media('mobile') {
      row-gap: 40px;
      margin-top: 30px;
      flex-direction: column;
    }
  }

  &__logo {
    opacity: 0.5;
    transition: 200ms;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
