@import "src/_mixin.scss";

.getOrder {
  background-color: $cta;
  padding: 2rem 0;

  @include min_media('medium') {
    padding: 60px 0;
  }

  &__layout {
    display: flex;

    @include media('mobile') {
      flex-direction: column-reverse;
    }
  }

  &__text {
    width: 620px;
    margin-right: 33px;
    @include media('mobile') {
      width: auto;
      margin-right: 0;
    }
  }

  &__title {
    margin-bottom: 32px;
  }

  &__description {
    margin-bottom: 30px;
  }

  &__image {
    position: relative;
    width: 241px;

    &Svg {
      position: absolute;
    }

    @include media('mobile') {
      text-align: center;
      width: auto;
      &Svg {
        position: static;
        width: 170px;
        height: 225px;
      }
    }
  }

  &__button {
    color: $dark-grey;
    font-size: 16px;
    line-height: 26px;
    padding: 14px 35px;
    border-width: 0;

    &:hover {
      color: $dark-grey;
      background-color: $early-dawn;
    }
  }
}
