@import "src/_mixin.scss";

.termsAndConditions {
  @include font_params(12px, 15px, 400, $grey);

  &__text {
    &_expandable {
      max-height: 0;
      overflow: hidden;
      transform: translateY(-10px);
      opacity: 0;
      transition: all .3s;
    }

    a {
      color: $grey;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &_expanded &__text_expandable {
    max-height: 200px;
    margin-top: 15px;
    transform: translateY(0);
    opacity: 1;
  }

  &__expand {
    color: $grey;
    text-decoration: underline;
    padding: 0;

    &:hover {
      text-decoration: none;
    }
  }

  &_expanded &__expand {
    display: none;
  }

}
