@import "src/_mixin.scss";

.ukrainianDisclaimer {
  border-bottom: 1px solid $border-dark;
  font-size: 12px;
  line-height: 1.2;
  padding: 1em 0;

  @include min_media('medium') {
    font-size: 14px;
    margin-bottom: -2rem;
    line-height: 1.5;
  }
}

.ukrainianDisclaimer__inner {
  display: block;
  font-weight: normal;
}
