@import "src/_mixin.scss";

.registerHero {
  padding-bottom: 2rem;
  @include min_media('medium') {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.registerHero__layout {
  @include container;

  @include min_media('medium') {
    display: grid;
    grid-template-columns: 380px 1fr;
    grid-template-rows: auto 1fr;
  }
}

.registerHero__header {
  padding: 40px 15px;

  @include min_media('medium') {
    position: sticky;
    top: 0;
    padding: 50px 0 0;
  }
}

.registerHero__prefix {
  margin-bottom: 2rem;
  color: $dark-grey;
}

.registerHero__suffix {
  margin-top: 2rem;
}

.registerHero__appButton {
  margin-top: 40px;
}

.registerHero__form {
  border-radius: $global-border-radius;
  background-color: $body-background;
  min-width: 290px;
  max-width: 460px;
  width: 100%;
  padding: 2rem 1rem;

  @include min_media('medium') {
    grid-row: 1/3;
    grid-column: 2/3;
    justify-self: flex-end;
    padding: 2rem;
  }
}

.registerHero__media {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px -30px 0;
  padding: 0;
  width: 100vw;
  border-width: 0;
  aspect-ratio: 380/250;
  background-color: $cta;
  background-image: url(poster.jpg);
  background-size: cover;
  background-position: center;
  cursor: pointer;

  &:focus {
    @include focus_state;
  }

  &:hover .registerHero__play {
    background-color: $black;
  }

  @include min_media('medium') {
    max-width: 380px;
    height: 250px;
    margin: 50px 0 0;
    aspect-ratio: auto;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    background-image: url(poster@2x.jpg);
  }
}

.registerHero__play {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: $dark-grey;
  border-radius: 50%;
  color: $white;
  animation: pulse 2s infinite;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: pulse 2s infinite;
    animation-delay: 800ms;
  }
}

.registerHero__sticker {
  display: none;

  @include min_media('medium') {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    padding: 2rem 0;
    background-color: $white;
    text-align: center;
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.05);
  }
}

.registerHero__sticker_visible {
  @include min_media('medium') {
    display: block;
  }
}

.registerHero__actionLayout {
  margin: 1.5rem auto 0;
  display: grid;
  grid-template-columns: 290px 290px;
  grid-column-gap: 2rem;
  width: min-content;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($dark-grey, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba($dark-grey, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($dark-grey, 0);
  }
}
