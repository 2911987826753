@import "src/_mixin.scss";

.providerRegisterCategories {
  padding: 0;
  margin: 0;
  border-width: 0;
}

.providerRegisterCategories__items {
  padding: 1rem 0;
  column-count: 2;
}

.providerRegisterCategories__item {
  margin-bottom: 0.75rem;
}

.providerRegisterCategories__error {
  padding-bottom: 1rem;
}
