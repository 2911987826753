@import "src/_mixin.scss";

.providerRegisterOrders {
  padding: 2rem 0 1rem;

  @include min_media('medium') {
    padding: 3rem 0;
  }
}

.providerRegisterOrders__layout {
  @include container;
}

.providerRegisterOrders__items {
  margin: 3rem -15px;

  @include min_media('medium') {
    margin: 3rem 0;
  }
}

.providerRegisterOrders__actions {
  text-align: center;
}
