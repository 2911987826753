@import "src/_mixin.scss";

.providerRegisterFaqs {
  padding: 1rem 0;

  @include min_media('medium') {
    padding: 3rem 0;
  }

  &__container {
    @include container;
  }

  &__accordion {
    margin: 1.5rem -15px 0;

    @include min_media('medium') {
      margin: 3rem 0 0;
    }
  }
}

.providerRegisterFaq {
  overflow: hidden;
  border-color: $border-light;
  border-style: solid;
  border-width: 1px 0;

  & + & {
    border-top: 0;
  }

  &__panel {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;
  }

  &__question {
    @include poppins_font(18px, 24px, 400, $text-primary);
    display: flex;
    width: 100%;
    margin: 0;
    padding: 20px 10px 20px 0;
    cursor: pointer;
    transition: all 0.3s;

    @include media('mobile') {
      text-align: left;
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  &__question:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin-left: auto;
    align-self: center;
    background: url('~common/icons/arrow_down.svg') center no-repeat;
    transition: transform 0.2s;
  }

  &_open &__question:after {
    transform: rotate(180deg);
  }

  &__answer {
    margin: 40px 0 40px 160px;
    font-size: 16px;
    line-height: 22px;
    color: $text-grey;

    @include media('mobile') {
      margin: 20px 0 0;
      padding: 0 35px 30px 35px;
    }
  }

  &_open &__question {
    font-size: 22px;
    color: $text-dark-grey;
  }

  &_open &__panel {
    max-height: 400px;
  }
}
