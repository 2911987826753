@import "src/_mixin.scss";

.ordersAccordion__toggle {
  padding: 1rem;

  @include min_media('medium') {
    padding: 1rem 1rem 1rem 0;
  }
}

.ordersAccordion__section {
  border-top: 1px solid $border-dark;

  &:last-child {
    border-bottom: 1px solid $border-dark;
  }
}

.ordersAccordion__children {
  padding: 0 1.5rem 1rem;
  margin: 0;
  @include min_media('medium') {
    padding: 0 0 1rem;
    column-count: 3;
  }
}

.ordersAccordion__heading {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  min-height: 46px;
  color: $dark-grey;
}

.ordersAccordion__icon {
  flex-shrink: 0;
}

.ordersAccordion__item {
  margin: 0.5rem 0;

  &:first-child {
    margin-top: 0;
  }
}
