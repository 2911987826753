@import "src/_mixin.scss";

.providerRegisterForm__action {
  margin-top: 1rem;
}

.providerRegisterForm__secondStep {
  max-width: 460px;
  padding: 0 1rem;
  margin: auto;
}

.providerRegisterForm__selectControl {
  background-image: url('~common/icons/general/icon_profile.svg');
  background-position: 0.5rem center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  padding-left: 32px;
  height: 46px;
  border-color: $border-dark !important;
}

.providerRegisterForm__selectControl_focus {
  border-color: $primary !important;
  box-shadow: none !important;
}

.providerRegisterForm__selectControl_invalid {
  border-color: $error-color !important;
  box-shadow: none !important;
}

.providerRegisterForm__selectValue,
.providerRegisterForm__selectPlaceholder {
  color: $dark-grey !important;
}

.providerRegisterForm__selectPlaceholder {
  opacity: 0.5;
}
