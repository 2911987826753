@import "src/_mixin.scss";

.appReviews {
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 70px;

  @include min_media('medium') {
    padding-top: 3rem;
  }

  &__title,
  &__subTitle {
    text-align: center;
  }

  &__subTitle {
    margin-top: 1rem;
  }

  &__wrap {
    position: relative;
    margin: 0 auto;
    max-width: 940px;
    margin-top: 3rem;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      max-width: 960px;
      height: 230px;
      margin: -264px auto 0 auto;
      box-shadow: 0 294px 64px rgba(0, 0, 0, 0.06);
    }

    .slick-arrow {
      position: absolute;
      width: 45px;
      height: 150px;
      padding: 0;
      background: none;
      border: none;
      z-index: 2;
      outline: none;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 45px;
        height: 45px;
        margin: auto;
        background-color: $cta;
        border-radius: 50%;
        transition: transform 0.2s;
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 10px;
        height: 10px;
        margin: auto;
        border: 2px solid $dark-grey;
        border-width: 2px 2px 0 0;
        transform: rotate(-135deg) translate(-2px, 2px);
      }
    }

    .slick-prev {
      left: -75px;
    }

    .slick-next {
      right: -75px;

      &:after {
        transform: rotate(45deg) translate(-2px, 2px);
      }
    }

    .slick-initialized .slick-slide {
      text-align: center;
    }

    .slick-list {
      position: relative;
      margin: 0 -10px;
    }

    @include media('mobile') {
      padding-left: 25px;
      padding-right: 25px;
      .slick-arrow::before {
        width: 40px;
        height: 40px;
      }
      .slick-prev {
        left: -10px;
      }
      .slick-next {
        right: -10px;
      }
    }
  }

  &__item {
    text-align: left;
    max-width: 300px;
    min-height: 234px;
    margin: 0;
    padding: 25px;
    border-radius: 10px;
    background-color: $white;
  }

  &__stars {
    margin-bottom: 15px;
  }

  &__author,
  &__store {
    @include lato_font(13px, 17px, 400, $silver-chalice);
  }

  &__author {
    margin-bottom: 1rem;
  }

  &__description {
    height: 104px;
    overflow: hidden;
  }
}
