@import "src/_mixin.scss";

.blurryIconList {
  display: grid;
  grid-row-gap: 50px;
  margin: 0 auto;
  padding-left: 0;

  @include min_media('medium') {
    grid-row-gap: 80px;
    grid-column-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
}

.blurryIconList__row {
  display: flex;
}

.blurryIconList__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  background-position: center;
  background-size: contain;
  border-radius: 10px;
  box-shadow: 0 30px 64px rgba(0, 0, 0, 0.06);

  @include min_media('medium') {
    margin-right: 2rem;
  }
}

.blurryIconList__icon_bag {
  background-image: url(./icons/bag.jpg);
  @include retina {
    background-image: url(./icons/bag@2x.webp);
  }
}

.blurryIconList__icon_card {
  background-image: url(./icons/card.jpg);
  @include retina {
    background-image: url(./icons/card@2x.webp);
  }
}

.blurryIconList__icon_clock {
  background-image: url(./icons/clock.jpg);
  @include retina {
    background-image: url(./icons/clock@2x.webp);
  }
}

.blurryIconList__icon_flash {
  background-image: url(./icons/flash.jpg);
  @include retina {
    background-image: url(./icons/flash@2x.webp);
  }
}

.blurryIconList__icon_math {
  background-image: url(./icons/math.jpg);
  @include retina {
    background-image: url(./icons/math@2x.webp);
  }
}

.blurryIconList__icon_message {
  background-image: url(./icons/message.jpg);
  @include retina {
    background-image: url(./icons/message@2x.webp);
  }
}

.blurryIconList__icon_note {
  background-image: url(./icons/note.jpg);
  @include retina {
    background-image: url(./icons/note@2x.webp);
  }
}

.blurryIconList__icon_profile {
  background-image: url(./icons/profile.jpg);
  @include retina {
    background-image: url(./icons/profile@2x.webp);
  }
}

.blurryIconList__icon_shield {
  background-image: url(./icons/shield.jpg);
  @include retina {
    background-image: url(./icons/shield@2x.webp);
  }
}

.blurryIconList__icon_star {
  background-image: url(./icons/star.jpg);
  @include retina {
    background-image: url(./icons/star@2x.webp);
  }
}

.blurryIconList__icon_wallet {
  background-image: url(./icons/wallet.jpg);
  @include retina {
    background-image: url(./icons/wallet@2x.webp);
  }
}

.blurryIconList__lead {
  margin-top: 0.5rem;
}
