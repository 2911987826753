@import "src/_mixin.scss";

.pageScroller {
  position: fixed;
  right: 1rem;
  bottom: 1rem;

  @include min_media('medium') {
    right: 4rem;
    bottom: 4rem;
  }
}
